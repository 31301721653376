input,
textarea {
  padding-block: 0px;
  padding-inline: 0px;
}

button {
  padding-block: 0px;
  padding-inline: 0px;
  cursor: pointer;
}

body {
  background-color: white;
}
